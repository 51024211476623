import React from 'react';
import PropTypes from 'prop-types';

import { formatDate } from 'common/helpers';
import Link from 'atomic-design/atoms/link';
import Image from 'core/image';
import {
  Container,
  DateAndCategoryContainer,
  PublishedDate,
  Title,
  InformationContainer,
  Category,
} from './styles';

const Post = ({ slug, title, publishedDate, categoryName, background, hero, maxWidthText }) => {
  return (
    <Link to={`/blog/${slug}`} rel={slug}>
      <Container>
        <Image
          alt={title}
          contentful_id={hero}
          fromContentful
          desktopWidth="250px"
          tabletLandscapeWidth="250px"
          tabletWidth="250px"
          mobileHeight="180px"
          width="100%"
          fluid
        />
        <InformationContainer maxWidthText={maxWidthText}>
          <DateAndCategoryContainer>
            <Category color={background}>{categoryName}</Category>
            <PublishedDate>{formatDate(publishedDate)}</PublishedDate>
          </DateAndCategoryContainer>
          <Title>{title}</Title>
        </InformationContainer>
      </Container>
    </Link>
  );
};

Post.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  publishedDate: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
};

export default Post;
