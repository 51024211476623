import styled from 'styled-components';

export const Container = styled.div`
  margin: ${({ theme }) => theme.newTheme.spacing(5)} 0;
  display: flex;
  border-radius: ${({ theme }) => theme.newTheme.border.radius.medium};
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.newTheme.shadow.main};

  &:hover {
    h6 {
      text-decoration: underline;
    }

    @keyframes zoom {
      from {
        transform: scale(1);
      }
      to {
        transform: scale(1.05);
      }
    }

    img {
      animation: zoom 0.3s ease-in-out 0s 1;
      transform: scale(1.05);
    }
  }

  width: 100%;
  background: ${({ theme }) => theme.newTheme.color.white};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    flex-direction: column;
    margin-bottom: ${({ theme }) => theme.newTheme.spacing(6)};
  }
`;

export const PublishedDate = styled.h4`
  color: ${({ theme }) => theme.newTheme.color.grey.light};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  font-size: ${({ theme }) => theme.newTheme.font.text3.size};
  line-height: ${({ theme }) => theme.newTheme.font.text3.lineHeight};
  padding: 0;
  margin: 0;
`;
export const Title = styled.h6`
  color:  ${({ theme }) => theme.newTheme.color.black};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  font-size: ${({ theme }) => theme.newTheme.font.title6.size};
  line-height: ${({ theme }) => theme.newTheme.font.title6.lineHeight};
  margin 0;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.text1.size};
    line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
  }
`;
export const Subtitle = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.newTheme.color.grey.main};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  font-size: ${({ theme }) => theme.newTheme.font.text1.size};
  line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.text2.size};
    line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
  }
`;

export const Excerpt = styled.p`
  color: ${({ theme }) => theme.newTheme.color.grey.light};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  font-size: ${({ theme }) => theme.newTheme.font.text2.size};
  line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
  margin: 0;
`;

export const DateAndCategoryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
export const InformationContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.newTheme.spacing(6)};
  flex-direction: column;
  padding: ${({ theme }) => theme.newTheme.spacing(6)};
  max-width: ${props => props.maxWidthText};
  min-width: ${props => props.minWidthText};
  height: 100%;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    ${Excerpt} {
      display: none;
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    width: 100%;
    min-width: 100%;
  }
`;

export const Category = styled.div`
  color: ${({ theme }) => theme.newTheme.color.white};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  font-size: ${({ theme }) => theme.newTheme.font.text3.size};
  line-height: ${({ theme }) => theme.newTheme.font.text3.lineHeight};
  background: ${({ color }) => color};
  display: flex;
  align-content: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.newTheme.border.radius.button};
  ${({ theme }) => `padding: ${theme.newTheme.spacing(2)} ${theme.newTheme.spacing(5)}`};

  @media only screen and (${props => props.theme.breakpoints.tablet}) {
    ${({ theme }) => `padding: ${theme.newTheme.spacing(1)} ${theme.newTheme.spacing(3)}`};
  }
`;
