import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from 'core/layout';
import MetaTags from 'core/meta-tags/index';
import { getCategoryColor } from 'common/helpers';
import Post from 'atomic-design/molecules/post/small-post';
import PageSection from 'atomic-design/atoms/page-section';
import theme from 'styles/theme';

import {
  HeaderText,
  Heading1,
  Heading2,
  Subtitle,
  BodyText,
  Bold,
  List,
  Text,
  RelativeContainer,
  BackgroundColor,
  PostContainer,
} from '../styles/pages/quality-policy-styles';

export default () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulBlogPost(slug: { eq: "why-should-a-software-development-company-certify-iso" }) {
        title
        subtitle
        slug
        hero {
          contentful_id
          file {
            url
          }
        }
        publishedDate
        excerpt {
          excerpt
        }
        category {
          name
          slug
        }
      }

      allContentfulPage(filter: { url: { eq: "quality-policy" } }) {
        edges {
          node {
            metatagImage {
              file {
                url
              }
            }
            metatagTitle
            metatagDescription
          }
        }
      }
    }
  `);

  const metatagImage = data.allContentfulPage.edges[0].node.metatagImage.file.url;
  const { metatagDescription } = data.allContentfulPage.edges[0].node;
  const { metatagTitle } = data.allContentfulPage.edges[0].node;

  return (
    <Layout bodyType="xWide">
      <MetaTags
        title={metatagTitle}
        description={metatagDescription}
        charSet="utf-8"
        image={metatagImage}
        viewport="width=device-width, initial-scale=1"
        type="article"
      />
      <PageSection
        padding={`${theme.newTheme.spacing(20)} 0 ${theme.newTheme.spacing(9)}`}
        containerSize="s"
      >
        <HeaderText>ISO 9001:2015</HeaderText>
        <Heading1>Quality Policy</Heading1>
        <Subtitle>Small enough to care, big enough to get the job done</Subtitle>
        <BodyText>
          We are well aware that client and team satisfaction is the key to a healthy long-term
          business growth. That’s why NaNLABS is committed to delivering quality software to its
          clients while offering a respectful and challenging work environment for its employees.
        </BodyText>
        <BodyText>
          In order to give the right priority to these objectives, we engaged in the process of ISO
          certification. And we are now certified!
        </BodyText>
        <BodyText padding="0 0 26px 0">
          It has helped us formalize our processes, implement new ones and question our practices.
          We work in an agile and iterative ways, aiming at continuous improvement. Read more about
          our road to define agile.
        </BodyText>
      </PageSection>
      <RelativeContainer>
        <BackgroundColor />
        <Heading2 padding="52px 0 26px 0">Quality Policy</Heading2>
        <BodyText>
          <Bold>NaN</Bold>
          LABS, company dedicated to software development, is committed to the following objectives:
        </BodyText>
        <List>
          <BodyText>
            <li>
              <BodyText>
                Offering services that satisfy our clients, thanks to the
                <Bold color="#5D73F5"> commitment</Bold>,<Bold color="#5D73F5"> ethic </Bold>
                and
                <Bold color="#5D73F5"> effort </Bold>
                of all employees.
              </BodyText>
            </li>
            <li>
              <BodyText>
                Continuously
                <Bold color="#5D73F5"> training </Bold>
                and keeping all staff updated,
                <Bold color="#5D73F5"> motivating </Bold>
                its employees looking for the
                <Bold color="#5D73F5"> best </Bold>
                efficiency and generating an environment that favors the comprehensive growth of its
                people.
              </BodyText>
            </li>
            <li>
              <BodyText>
                <Bold color="#5D73F5">Meeting all the legal applicable requirements </Bold>
                from our clients and all interested parties. And to continuously improve the
                efficiency of our quality management system, according to the
                <Bold color="#5D73F5"> ISO 9001:2015 </Bold>
                standard, to improve our quality performance.
              </BodyText>
            </li>
          </BodyText>
        </List>
        <Text textAlign="right">
          <Bold color="#5D73F5">Julián Alessandro,</Bold>
        </Text>
        <Text textAlign="right" color="#5D73F5">
          President,
          <Bold color="#5D73F5"> NaN</Bold>
          LABS.
        </Text>
      </RelativeContainer>

      <Heading2 padding="104px 0 26px 0">Política de calidad</Heading2>
      <BodyText>
        <Bold> NaN</Bold>
        LABS, empresa dedicada al desarrollo de software, tiene como objetivo:
      </BodyText>
      <List>
        <li>
          <BodyText>
            Ofrecer servicios que satisfagan a nuestros clientes, a través del
            <Bold color="#5D73F5"> compromiso</Bold>,<Bold color="#5D73F5"> ética </Bold> y
            <Bold color="#5D73F5"> esfuerzo </Bold>
            de todos los empleados.
          </BodyText>
        </li>
        <li>
          <BodyText>
            <Bold color="#5D73F5">Capacitar </Bold>y actualizar en forma permanente a su personal,
            <Bold color="#5D73F5"> motivar </Bold>a sus empleados en busca de la
            <Bold color="#5D73F5"> mayor eficiencia </Bold>y generar un ambiente apto para el
            progreso integral de la persona.
          </BodyText>
        </li>
        <li>
          <BodyText>
            Asumir el compromiso de
            <Bold color="#5D73F5">cumplir con los requisitos aplicables</Bold>, legales, de nuestros
            clientes y partes interesadas, y de mejorar continuamente la eficacia del sistema de
            gestión de calidad de acuerdo a la norma
            <Bold color="#5D73F5"> ISO 9001:2015</Bold>, para la mejora del desempeño de la calidad.
          </BodyText>
        </li>
      </List>
      <Text textAlign="right">
        <Bold color="#5D73F5">Julián Alessandro,</Bold>
      </Text>
      <Text textAlign="right" color="#5D73F5">
        President,
        <Bold color="#5D73F5"> NaN</Bold>
        LABS.
      </Text>
      <PostContainer>
        <Post
          slug={data.contentfulBlogPost.slug}
          title={data.contentfulBlogPost.title}
          publishedDate={data.contentfulBlogPost.publishedDate}
          categoryName={data.contentfulBlogPost.category.name}
          background={getCategoryColor(data.contentfulBlogPost.category.slug)}
          hero={data.contentfulBlogPost.hero.contentful_id}
          margin="0"
          maxWidthText="70%"
        />
      </PostContainer>
    </Layout>
  );
};
