import styled from 'styled-components';

export const HeaderText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.primary};
  padding: 0 0 26px 0;
  margin: 0;
  width: 100%;
  max-width: 690px;

  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    padding-left: 27px;
    padding-right: 27px;
  }
`;

export const Heading1 = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  line-height: 80px;
  letter-spacing: -0.01em;
  color: ${({ theme }) => theme.colors.black};
  padding: 0 0 26px 0;
  margin: 0;
  width: 100%;
  max-width: 690px;

  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    padding-left: 27px;
    padding-right: 27px;
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 50px;
    line-height: 65px;
  }

  @media only screen and (${({ theme }) => theme.breakpoints.smallMobile}) {
    font-size: 40px;
    line-height: 55px;
  }
`;

export const Heading2 = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: -0.01em;
  color: ${({ theme }) => theme.colors.primary};
  padding: ${props => (props.padding ? props.padding : '0')};
  margin: 0;

  width: 100%;
  max-width: 690px;

  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    padding-left: 27px;
    padding-right: 27px;
  }

  @media only screen and (${({ theme }) => theme.breakpoints.smallMobile}) {
    font-size: 30px;
    line-height: 45px;
  }
`;

export const Subtitle = styled(HeaderText)`
  color: #4f4f4f;
  padding: 0 0 52px 0;
  width: 100%;
  max-width: 690px;

  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    padding-left: 27px;
    padding-right: 27px;
  }
`;

export const BodyText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: ${props => (props.color ? props.color : '#4f4f4f')};
  padding: ${props => (props.padding ? props.padding : '0')};
  text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
  width: 100%;
  max-width: 690px;

  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    padding-left: 27px;
    padding-right: 27px;
  }
`;

export const Text = styled(BodyText)`
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 690px;

  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    padding-left: 27px;
    padding-right: 27px;
  }
`;

export const Bold = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: ${props => (props.color ? props.color : '#4f4f4f')};
`;

export const List = styled.ul`
  list-style-type: ' • ';
  li {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const RelativeContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const BackgroundColor = styled.div`
  position: absolute;
  background: #f7f7f7;
  top: 20px;
  max-height: 600px;
  height: 100%;
  min-height: 100%;
  width: 100%;
  z-index: -1;
`;

export const PostContainer = styled.div`
  padding-top: 52px;
  width: 100%;
  max-width: 690px;

  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    padding-left: 27px;
    padding-right: 27px;
  }
`;
